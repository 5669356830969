import Footer from '../Footer';
import Header from '../Header';
import SitesGrid from '../SitesGrid';

const Products = () => (
  <>
    <Header themeButton />
    <SitesGrid />
    <Footer />
  </>
);

export default Products;
