import {
  useEffect,
  useState,
  useCallback,
} from 'react';
import AppStorage from './storage';

const storage = AppStorage.getStore();

const STORE_KEY = 'APP THEME KEY';

export const Themes = {
  DARK: {
    colorBG: '#141b34 ',
    colorBGV: '#011c39',
    colorFG: '#f5f8f3',
    colorAccent: '#89e101',
    colorFaint: '#f5f8f3',
  },
  LIGHT: {
    colorBG: '#fafafa',
    colorBGV: '#fff',
    colorFG: '#011c39',
    colorAccent: '#89e101',
    colorFaint: '#393939',
  },
};

const manager = (() => {
  let subscribers = [];

  const unsubscribe = (callback) => () => {
    subscribers = subscribers.filter((c) => c !== callback);
  };

  const subscribe = (listener) => {
    subscribers.push(listener);
    return unsubscribe(listener);
  };

  const update = (value) => {
    storage.set(STORE_KEY, value === Themes.DARK ? 'DARK' : 'LIGHT');
    subscribers.forEach((callback) => {
      try {
        callback(value);
      } catch {
        // TODO
      }
    });
  };

  return { subscribe, update };
})();

export const useTheme = () => {
  const [theme, setTheme] = useState(Themes[storage.get(STORE_KEY) || 'LIGHT']);

  useEffect(() => manager.subscribe((value) => setTheme(value)), []);

  const updateTheme = useCallback((value) => manager.update(value), []);

  return [theme, updateTheme];
};
