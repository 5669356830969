import policy from '../assets/documents/privacy policy.pdf';
import terms from '../assets/documents/terms_of_service.pdf';
import Header from './Header';

const styles = {
  container: {
    width: '100%',
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
  },
  frame: {
    width: '100%',
    border: 'none',
    flex: 1,
  },
};

export const PrivacyPolicy = () => (
  <div style={styles.container}>
    <Header backgroundColor="#323639" />
    <iframe title="privay-policy" style={styles.frame} src={policy} />
  </div>
);

export const TermsOfUse = () => (
  <div style={styles.container}>
    <Header backgroundColor="#323639" />
    <iframe title="privay-policy" style={styles.frame} src={terms} />
  </div>
);
