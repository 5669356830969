/* eslint-disable linebreak-style */
import { useMemo } from 'react';
import PropTypes from 'prop-types';
import Header from '../Header';
import css from './style.module.css';
import banner from '../../assets/images/banner.jpeg';
import francis from '../../assets/images/francis.jpeg';
import rabi from '../../assets/images/rabi.jpg';
import chris from '../../assets/images/chris.jpg';
import gerry from '../../assets/images/gerry.jpeg';
import tobi from '../../assets/images/tobi.jpeg';
import Kachikwu from '../../assets/images/kachikwu.jpg';

import Footer from '../Footer';
import { useTheme } from '../../utils/themes';

const teamMembersSeed = [
  {
    id: 1,
    firstname: 'Christopher',
    lastname: 'Adebajo',
    picture: chris,
    designation: 'Founder',
    about:
      'A seasoned IT expert, specializing in DevOps and software development, dedicated to driving innovation and excellence in the tech industry.',
    linkedIn: 'https://www.linkedin.com/in/christopher-adebajo-292386132/',
    x: '',
    dribble: '',
  },
  {
    id: 1,
    firstname: 'Gerald',
    lastname: 'Emeka',
    picture: gerry,
    designation: 'Co-Founder',
    about:
      'An extensive expertise in cybersecurity and security leverage. His deep understanding of DevSecOps and business analysis positions him as a pivotal force in shaping our security strategies and business direction.',
    linkedIn: 'https://www.linkedin.com/in/gerald-emeka-e-b855871a3/',
    x: '',
    dribble: '',
  },
  {
    id: 1,
    firstname: 'Emmanuel',
    lastname: 'Chasset',
    picture: francis,
    designation: 'Chief Operating Officer for Africa, ',
    about:
      'Highly knowledgeable in software engineering, application deployment, and maintenance, ensuring our technological advancements and operational excellence across the continent',
    linkedIn: '',
    x: '',
    dribble: '',
  },
  {
    id: 1,
    firstname: 'Ndaisah',
    lastname: 'Umar Rabi',
    picture: rabi,
    designation: 'Executive Director Development',
    about:
      'Highly Knowledgeable in designing solutions that scales well and are reliable. Passionate not just to design solutions that works but meets the standard and requirements. His Knowledge in Development cut across different platforms (web, mobile and standalone)',
    linkedIn: 'https://www.linkedin.com/in/umar-rabi-310689118',
    x: 'https://twitter.com/BarizheRabi',
    dribble: '',
  },
  {
    id: 1,
    firstname: 'Emmanuel',
    lastname: 'Kachikwu',
    picture: Kachikwu,
    designation: 'UI/UX Designer',
    about:
      'The architect of digital experiences, sculpting interfaces that marry form and function seamlessly. His expertise lies in understanding user behavior and translating it into intuitive designs that elevate user interactions. With his creative vision and meticulous attention to detail.',
    linkedIn: '',
    x: '',
    dribble: '',
  },
  {
    id: 1,
    firstname: 'Oluwatobi',
    lastname: 'Sule',
    picture: tobi,
    designation: 'Executive Director, Control and Quality Assurance',
    about:
      'He brings a dynamic blend of financial expertise and a strategic MBA background. His role is rooted in ensuring the highest standards of quality and compliance in software development, leverages both practical industry knowledge and academic insights to drive excellence.',
    linkedIn: 'https://www.linkedin.com/in/oluwatobisule',
    x: 'https://x.com/TMimperial',
    dribble: '',
  },
];

const teamMembers = (() => {
  const members = [];
  for (let i = 0; i < teamMembersSeed.length; i += 1) {
    members.push(teamMembersSeed[i]);
  }

  return members;
})();

// eslint-disable-next-line react/prop-types
const TeamMember = ({ member, color }) => {
  const name = useMemo(() => `${member.firstname} ${member.lastname}`, [
    member,
  ]);

  return (
    <section className={`${css.team_member_section} flex items-center justify-center`}>
      <img
        src={member.picture}
        alt={member.firstname}
        className={css.team_member_picture}
      />
      <header className="pt-6 pb-4">
        <h1 style={{ color }} className={`text-center ${css.team_member_name}`}>{name}</h1>
        <span style={{ color }} className={`text-center flex justify-center ${css.team_member_designation}`}>
          {member.designation}
        </span>
      </header>
      <p style={{ color }} className={`text-justify ${css.team_member_about}`}>{member.about}</p>
      <div className="pt-6 flex gap-4 w-full">
        <a
          style={{ color }}
          className={`${css.member_link} ${css.linkedIn}`}
          href={member.linkedIn}
          target="_blank"
          rel="noreferrer"
        >
          <span aria-hidden="true" className={css.icon} style={{ backgroundColor: color }} />
          <span>LinkedIn</span>
        </a>
        <a
          style={{ color }}
          className={`${css.member_link} ${css.x}`}
          href={member.x}
          target="_blank"
          rel="noreferrer"
        >
          <span aria-hidden="true" className={css.icon} style={{ backgroundColor: color }} />
          <span>X</span>
        </a>
        <a
          style={{ color }}
          className={`${css.member_link} ${css.dribble}`}
          href={member.dribble}
          target="_blank"
          rel="noreferrer"
        >
          <span aria-hidden="true" className={css.icon} style={{ backgroundColor: color }} />
          <span>Dribble</span>
        </a>
      </div>
    </section>
  );
};

TeamMember.propTypes = {
  member: PropTypes.shape({
    id: PropTypes.number,
    firstname: PropTypes.string,
    lastname: PropTypes.string,
    picture: PropTypes.string,
    designation: PropTypes.string,
    about: PropTypes.string,
    linkedIn: PropTypes.string,
    x: PropTypes.string,
    dribble: PropTypes.string,
  }).isRequired,
};

const AboutUs = () => {
  const [theme] = useTheme();

  return (
    <>
      <Header themeButton />
      <div className={css.top_wrap} style={{ backgroundColor: theme.colorBG }}>
        <section className={`${css.container} ${css.story_section}`}>
          <header className={css.story_header}>
            <h1 className={css.story_heading} style={{ color: theme.colorFG }}>
              <span>Our Journey Unfolded</span>
              <span className={css.story_sub_heading}>
                Crafting Our Narrative
              </span>
            </h1>
          </header>
          <div className={css.story_body} style={{ color: theme.colorFaint }}>
            <p className="py-2 leading-[27px] font-[Montserrat]">
              In the vibrant year of 2023, LogaXP emerged as the brainchild of
              two dynamic young professionals driven by a shared vision: to
              redefine and elevate the productivity landscape for businesses.
              Founded with a passion for innovation and a commitment to
              enhancing employee efficiency, LogaXP set out on a journey to
              revolutionize human resources productivity.
            </p>
            <p className="py-2 leading-[27px] font-[Montserrat]">
              What began as a visionary idea has now evolved into a thriving
              company with a team of over 10 dedicated professionals. LogaXP
              specializes in developing cutting-edge HR productivity
              applications tailored to meet the diverse needs of modern
              businesses. Our journey has been marked by relentless dedication,
              constant evolution, and a commitment to delivering solutions that
              empower organizations.
            </p>
            <p className="py-2 leading-[27px] font-[Montserrat]">
              With over 5 successful products currently in the market, LogaXP
              has positioned itself as a trailblazer in the realm of HR
              technology. Our suite of applications is designed to streamline
              processes, optimize workflows, and create an environment where
              employees can thrive. We understand that the heartbeat of any
              organization lies in the productivity and satisfaction of its
              workforce, and LogaXP is here to ensure that heartbeat remains
              strong.
            </p>
            <p className="py-2 leading-[27px] font-[Montserrat]">
              As we continue to grow, innovate, and push boundaries, LogaXP is
              not just a company; it&quot;s a testament to the power of passion
              and the pursuit of excellence. With an additional 5 products in
              development, we are excited about the future and the impact our
              solutions will have on businesses globally.
            </p>
            <p className="py-2 leading-[27px] font-[Montserrat]">
              Join us on this journey of transformation and productivity. LogaXP
              - Where Innovation Meets Efficiency.
            </p>
          </div>
        </section>
        <section className={`${css.container} ${css.banner_section}`}>
          <img className={css.banner} src={banner} alt="efficient" />
        </section>
      </div>
      <section style={{ backgroundColor: theme.colorBG }} className="p-8">
        <header className={`${css.team_header} `}>
          <h1 style={{ color: theme.colorFG ? theme.colorFG : 'black' }} className={css.team_heading}>Our Team</h1>
          <p style={{ color: theme.colorFG ? theme.colorFG : 'black' }} className={css.team_heading_rider}>
            Meet the people behind our success story.
          </p>
        </header>
        <div className="w-full max-w-[1140px] mx-auto my-4 grid grid-cols-3 gap-16 pt-12">
          {teamMembers.map((m) => (
            <TeamMember color={theme.colorFG ? theme.colorFG : 'black'} key={m.id} member={m} />
          ))}
        </div>
      </section>
      <div className={`${css.footer}`}>
        <Footer />
      </div>
    </>
  );
};

export default AboutUs;
