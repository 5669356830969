/**
 * @callback get
 * @argument {string} key
 * @returns {string}
 */

/**
 * @callback set
 * @argument {string} key
 * @argument {string} value
 * @returns {void}
 */

/**
 * @callback delete
 * @argument {string} key
 * @returns {void}
 */

/**
 * @typedef LocalStorage
 * @property {get} get
 * @property {set} set
 * @property {delete} delete
 */

/** type {LocalStorage} */
let storage;

const AppStorage = {
  /**
   * @param store
   * @returns {LocalStorage}
   */
  getStore: (store = localStorage) => {
    if (!storage) {
      storage = {
        get: (key) => store.getItem(key),
        set: (key, value) => store.setItem(key, value),
        delete: (key) => store.removeItem(key),
      };
    }

    return storage;
  },
};

export default AppStorage;
