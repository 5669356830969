/* eslint-disable linebreak-style */
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFacebookF,
  faTwitter,
  faLinkedinIn,
} from '@fortawesome/free-brands-svg-icons';
import QuickLinksSidebar from './QuickLinksSidebar';
import docIcon3 from '../../assets/images/homepage2.jpg';
import BlogHeader from './Header';
import BlogSideBar from './SideBar';
import RelatedArticles from './RelatedArticles';
import css from './styles.module.css';

const Blog = () => (
  <>
    <header className={css.blog_header}>
      <BlogHeader />
    </header>
    <div className={css.blog_layout}>
      <aside className={css.quick_links_sidebar}>
        <QuickLinksSidebar />
      </aside>
      <div className={css.blog_container}>
        <main className={css.blog_main}>
          <article className={css.blog_post}>
            <h3 className={css.blog_post_h3}>
              Embracing the Digital Revolution: The State of IT in Today&apos;s World
            </h3>
            <img src={docIcon3} alt="Digital Revolution" className={css.blog_main_image} />
            <div className={css.blog_content}>
              <h4>By Christopher Adebajo</h4>
              <span className={css.blog_title}>
                Embracing the Digital Revolution: The State of IT in Today&apos;s World
              </span>
              <p>
                The IT landscape is evolving at an unprecedented pace, driven by the relentless
                innovation in cloud computing, artificial intelligence, and cybersecurity. As we
                navigate through the digital revolution, IT professionals are the vanguards,
                steering us towards a smarter and more connected world.
              </p>
              <p>
                Companies are now investing heavily in IT infrastructure to harness the power
                of big data analytics, propelling decision-making processes with precision
                and foresight. The emergence of 5G technology is set to further revolutionize
                connectivity, promising a future where the internet is more accessible,
                reliable, and faster than ever before.
              </p>
              <p>
                With great power comes great responsibility, and the IT industry is no exception.
                As technology continues to permeate every aspect of our lives, ethical
                considerations and data privacy have taken center stage, ensuring that the
                digital future is secure and inclusive for all.
              </p>
              <>
                The IT world has experience a rise in demand for cloud computing, artificial
                intelligence, and cybersecurity.
                <a className={css.loga_url} href="https://www.logaxp.com">LogaXP</a>
                is a company that provides IT services to companies.
              </>
            </div>
            <div className={css.blog_content}>
              <h4 className={css.subheading}>Innovations in IT Services</h4>
              <p>
                The IT industry has seen exponential growth in areas such as cloud computing,
                artificial intelligence, and cybersecurity. These technologies are at the
                forefront of digital transformation...
              </p>
              <div className={css.blog_content_section}>
                <img src={docIcon3} alt="Innovative IT Services" className={css.content_section_image} />
                <div className={css.content_text}>
                  <p>
                    Cloud computing has democratized access to powerful computing resources,
                    enabling businesses of all sizes to scale efficiently.
                    <a className={css.loga_url} href="https://www.logaxp.com">LogaXP</a>
                    , as a provider of IT services, plays a pivotal role in implementing
                    cloud solutions...
                  </p>
                  <p>
                    Artificial intelligence is reshaping industries by automating complex tasks,
                    providing insights from data analytics, and enhancing customer experiences...
                  </p>
                </div>
              </div>
              <div className={css.blog_content}>
                <p>
                  Cybersecurity measures have become crucial as the reliance on digital platforms
                  increases. Companies like LogaXP are investing in robust security protocols to
                  protect sensitive data...
                </p>
                <p>
                  Devops are a new way of working that has been adopted by many companies.
                  Devops is an approach to software development that focuses on collaboration,
                  automation, and continuous delivery. It&apos;s also known as
                  &quot;agile&quot; or &quot;lean&quot;
                  because it emphasizes speed and flexibility over predictability and control.
                  Devops has been adopted by many companies because it helps them deliver
                  software faster and more reliably than traditional methods.
                </p>
              </div>
              {/* More subsections and content can follow */}
            </div>
            <div className={css.social_shares}>
              <span>Share this article:</span>
              <FontAwesomeIcon icon={faFacebookF} />
              <FontAwesomeIcon icon={faTwitter} />
              <FontAwesomeIcon icon={faLinkedinIn} />
            </div>
          </article>
        </main>
        <aside className={css.blog_sidebar}>
          <BlogSideBar />
        </aside>
        <footer className={css.blog_footer}>
          <RelatedArticles />
        </footer>
      </div>
    </div>
  </>
);

export default Blog;
