/* eslint-disable import/no-extraneous-dependencies */
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Dialog from '../lib/Dialog';
import Router from '../routing/router';

const App = () => (
  <>
    <Router />
    <Dialog />
    <ToastContainer />
  </>
);

export default App;
