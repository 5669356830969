import PropTypes from 'prop-types';

export const childrenProps = PropTypes.oneOfType([
  PropTypes.node,
  PropTypes.arrayOf(PropTypes.node),
  PropTypes.elementType,
  PropTypes.arrayOf(PropTypes.elementType),
]);

export const genericShape = PropTypes.shape({});
