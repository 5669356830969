/* eslint-disable react/prop-types */
import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import css from './style.module.css';
import Header from '../Header';
import Footer from '../Footer';
import Trusts from './Trusts';
import { Themes, useTheme } from '../../utils/themes';
import banner from '../../assets/images/banner.jpeg';
import { featuredSites } from '../../utils/sites';
import routes from '../../routing/routes';
import samplePoster from '../../assets/images/blog-sample-poster.jpeg';
import testimonial1 from '../../assets/images/testimonial-1.jpg';
import testimonial2 from '../../assets/images/testimonial-2.jpg';
import {
  FadeIn,
  ScaleOut,
  SlideFromBottom,
  SlideFromLeft,
  SlideFromRight,
} from '../../components/Slides';

const blogs = [
  {
    id: 1,
    category: 'category',
    duration: 20,
    title: 'Blog title heading will go here',
    summary:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros.',
    poster: samplePoster,
  },
  {
    id: 2,
    category: 'category',
    duration: 20,
    title: 'Blog title heading will go here',
    summary:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros.',
    poster: samplePoster,
  },
  {
    id: 3,
    category: 'category',
    duration: 20,
    title: 'Blog title heading will go here',
    summary:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros.',
    poster: samplePoster,
  },
  {
    id: 4,
    category: 'category',
    duration: 20,
    title: 'Blog title heading will go here',
    summary:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros.',
    poster: samplePoster,
  },
];

const testimonialSeeds = [
  {
    id: 1,
    rating: 5,
    name: 'Jenny Wilson',
    designation: 'Grower.io',
    picture: testimonial1,
    content:
      '"We love logaxp! Our designers were using it for their projects, so we already knew what kind of design they want."',
  },
  {
    id: 2,
    rating: 5,
    name: 'Jenny Wilson',
    designation: 'Grower.io',
    picture: testimonial2,
    content:
      '"We love logaxp! Our designers were using it for their projects, so we already knew what kind of design they want."',
  },
];

export const testimonials = (() => {
  const array = [];

  for (let i = 0; i < 10; i += 1) {
    const id = i * 2 + 1;
    array.push({ ...testimonialSeeds[0], id });
    array.push({ ...testimonialSeeds[1], id: id + 1 });
  }

  return array;
})();

const Hero = () => {
  const [theme] = useTheme();

  const isDarkMode = theme === Themes.DARK;

  return (
    <>
      <section
        className={css.body}
        style={{ backgroundColor: isDarkMode ? theme.colorBG : 'transparent' }}
      >
        <ScaleOut>
          <div className={`${css.hero_wrap} w-full pt-9 px-0 md:px-6 md:pt-6 flex flex-col gap-7`}>
            <p
              className={`${css.hero_intro} px-20 py-11 border border-black rounded-full font-display font-semibold leading-18 tracking-normal text-center text-[10px] md:text-sm w-full md:w-auto`}
            >
              innovation meets excellence in HR solutions and custom IT services
            </p>
            <h1 className={css.hero_heading} style={{ color: theme.colorFG }}>
              <div>
                <span>Unlock Efficiency and</span>
                <span style={{ color: theme.colorAccent }}>
                  &nbsp;Boost&nbsp;
                </span>
                <span>Performance</span>
              </div>
              <div>
                <span>with our Cutting-edge&nbsp;</span>
                <span style={{ color: theme.colorAccent }}>Solutions</span>
              </div>
            </h1>
            <p className={css.hero_body} style={{ color: theme.colorFaint }}>
              LogaXP is your trusted partner for cutting-edge software products.
              Our team of IT engineers and developers is dedicated to crafting
              solutions that elevate your business and keep you above
              competition
            </p>
            <div className={css.hero_explore_wrap}>
              <Link className="accent-btn" to={routes.allProducts}>
                Explore Our Services
              </Link>
            </div>
          </div>
        </ScaleOut>
      </section>
      <section
        className={`${css.body} mx-36`}
        style={{ backgroundColor: isDarkMode ? theme.colorBG : 'transparent' }}
      >
        <img alt="banner" className={css.hero_banner} src={banner} />
      </section>
      <section className={`${css.body} mx-36`} style={{ backgroundColor: theme.colorBG }}>
        <div className={css.about_wrap}>
          <span className={`${css.intro} border-b-2 border-green-400 w-auto px-4 hidden`}>About Us</span>
          <div className="w-full flex flex-col sm:flex-row gap-4 sm:space-x-8 justify-center">
            <SlideFromLeft className={css.about_heading}>
              <h1
                className={`${css.about_heading} text-[32px] sm:text-[40px] text-center sm:text-left sm:min-w-[348px]`}
                style={{ color: theme.colorFG }}
              >
                <span>Effective Solution</span>
                <br />
                <span>for your business</span>
              </h1>
            </SlideFromLeft>
            <SlideFromRight className={css.about_body}>
              <p className={`${css.about_body} w-full max-w-[600px]`} style={{ color: theme.colorFaint }}>
                LogaXP is your trusted partner for cutting-edge software
                products. Our team of IT engineers and developers is dedicated
                to crafting solutions that elevate your business and keep you
                above competition
              </p>
            </SlideFromRight>
          </div>
        </div>
      </section>
    </>
  );
};

const Site = ({ product, theme }) => (
  <SlideFromBottom>
    <section className="grid grid-cols-1 sm:grid-cols-2 gap-8">
      <div className={css.site_image_wrap}>
        <div className={css.site_sample_poster_wrap}>
          <img
            className={css.site_sample_image}
            src={product.homePoster}
            alt="sample"
          />
        </div>
        <div className={css.site_home_poster_wrap}>
          <img
            className={css.site_home_image}
            src={product.samplePoster}
            alt="sample"
          />
        </div>
      </div>
      <div
        className={css.site_text_wrap}
        style={{ backgroundColor: theme.colorBGV }}
      >
        <h1 className={css.section_heading} style={{ color: theme.colorFG }}>
          {product.title}
        </h1>
        <p className={css.site_summary} style={{ color: theme.colorFaint }}>
          <span>{product.summary}</span>
        </p>
        <ul className={css.site_features_list}>
          {product.features.map((feature) => (
            <li
              key={feature}
              className={css.site_feature_item}
              style={{ color: theme.colorFaint }}
            >
              <span>{feature}</span>
            </li>
          ))}
        </ul>
        <div className={css.site_controls}>
          <a
            href={product.homePage}
            target="_blank"
            rel="noreferrer"
            className={`hollow-btn ${css.explore}`}
          >
            Explore
          </a>
        </div>
      </div>
    </section>
  </SlideFromBottom>
);

Site.propTypes = {
  product: PropTypes.shape({
    homePoster: PropTypes.string,
    samplePoster: PropTypes.string,
    poster: PropTypes.string,
    title: PropTypes.string,
    homePage: PropTypes.string,
    summary: PropTypes.string,
    features: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  theme: PropTypes.shape({
    colorAccent: PropTypes.string,
    colorFG: PropTypes.string,
    colorBG: PropTypes.string,
    colorBGV: PropTypes.string,
    colorFaint: PropTypes.string,
  }).isRequired,
};

const FeaturedProducts = () => {
  const [theme] = useTheme();

  return (
    <section className={css.body} style={{ backgroundColor: theme.colorBG }}>
      <div className={css.products_wrap}>
        <span className={`${css.intro} ${css.center}`}>Our Products</span>
        <h1 className={css.section_heading} style={{ color: theme.colorFG }}>
          Featured Products
        </h1>
        <p className={css.products_summary} style={{ color: theme.colorFaint }}>
          Explore our range of products, and stay tuned, as we have more
          exciting apps in the pipeline, ready to reshape your world
        </p>
        <ul className={css.products_list}>
          {featuredSites.map((site) => (
            <li key={site.title} className={css.list_item}>
              <Site product={site} theme={theme} />
            </li>
          ))}
        </ul>
        <div className={css.section_controls}>
          <Link to={routes.allProducts} className="white-btn">
            View All Products
          </Link>
        </div>
      </div>
    </section>
  );
};

const Blog = ({ blog }) => {
  const duration = useMemo(() => {
    const hrs = Math.floor(blog.duration / 60);
    const mins = Math.floor(blog.duration % 60);
    let duration = `${mins} mins`;
    if (hrs) {
      duration = `${hrs}hr${hrs > 1 ? 's' : ''} ${duration}`;
    }

    return duration;
  }, [blog]);

  return (
    <FadeIn>
      <section className={css.blog}>
        <img className={css.blog_poster} src={blog.poster} alt="poster" />
        <div className={css.blog_category_wrap}>
          <span className="text-accent">{blog.category}</span>
          <span className={css.blog_duration}>{`${duration} read`}</span>
        </div>
        <h1 className={css.blog_title}>{blog.title}</h1>
        <p className={css.blog_summary}>{blog.summary}</p>
        <div className={css.blog_controls}>
          <Link to={routes.blogs(blog.id)} className={css.blog_link}>
            <span>Read More</span>
          </Link>
        </div>
      </section>
    </FadeIn>
  );
};

Blog.propTypes = {
  blog: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
    category: PropTypes.string,
    duration: PropTypes.number,
    summary: PropTypes.string,
    poster: PropTypes.string,
  }).isRequired,
};

export const Blogs = () => (
  <FadeIn>
    <section className={`${css.body} ${css.sm} ${css.blogs_section}`}>
      <span className={css.intro}>Blog</span>
      <h1 className={`${css.section_heading} ${css.left}`}>Featured Posts</h1>
      <ul className={css.blogs_panel}>
        {blogs.map((blog) => (
          <li key={blog.id}>
            <Blog blog={blog} />
          </li>
        ))}
      </ul>
      <div className={css.section_controls}>
        <Link className="white-btn" to={routes.blogsList}>
          Browse All Posts
        </Link>
      </div>
    </section>
  </FadeIn>
);

const Testimonial = ({ testimonial }) => (
  <section className={css.testimonial}>
    <img src={testimonial.poster} alt="reviewer" />
  </section>
);

Testimonial.propTypes = {
  testimonial: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    designation: PropTypes.string,
    poster: PropTypes.string,
    content: PropTypes.string,
  }).isRequired,
};

const FooterAction = () => (
  <ScaleOut>
    <section className={`${css.body} ${css.footer_action} dark-bg`}>
      <h1 className={css.footer_action_heading}>
        <span>looking for effective solutions to boost</span>
        <span>
          <span>&nbsp;your business? try&nbsp;</span>
          <span className="text-accent">LogaXP</span>
          <span>&nbsp;today</span>
        </span>
      </h1>
      <Link to={routes.allProducts} className="accent-btn">
        Explore Products
      </Link>
    </section>
  </ScaleOut>
);

const Home = () => (
  <div className={css.screen}>
    <Header themeButton />
    <Hero />
    <Trusts />
    <FeaturedProducts />
    <Blogs />
    <FooterAction />
    <Footer />
  </div>
);

export default Home;
