const routes = {
  home: '/',
  contact: '/contact',
  about: '/about',
  privacyPolicy: 'privacy-policy',
  termsAndConditions: '/terms-conditions',
  allProducts: 'all-products',
  blogsList: 'blogs',
  blogs: (id) => `blogs/${id}`,
};

export default routes;
