import { useEffect, useState } from 'react';
import css from './style.module.css';
import myers from '../../assets/images/myers.jpg';
import brogo from '../../assets/images/brogo.jpg';
import hinets from '../../assets/images/hinets.jpg';
import boom from '../../assets/images/boom.jpg';
import rose from '../../assets/images/rose.jpg';
import nshop from '../../assets/images/nshop.jpg';
import nicos from '../../assets/images/nicos.jpg';
import bella from '../../assets/images/bella.jpg';

const trustedByImages = [
  { id: 1, title: 'Myers', src: myers },
  { id: 2, title: 'Brogo', src: brogo },
  { id: 3, title: 'Hinets', src: hinets },
  { id: 4, title: 'Boom Inc', src: boom },
  { id: 5, title: 'Rose And Rex', src: rose },
  { id: 6, title: 'Nshop', src: nshop },
  { id: 7, title: 'Nicos', src: nicos },
  { id: 8, title: 'Bella Ellis', src: bella },
];

const Trusts = () => {
  const [motionEnabled, setMotionEnabled] = useState(true);

  useEffect(() => {
    if (window.matchMedia('(prefers-reduced-motion: reduce)').matches) {
      setMotionEnabled(false);
    }
  }, []);

  return (
    <div className={css.trusts_row}>
      <section className={`${css.section_row} ${css.trusts_panel}`}>
        <h1 className={css.section_heading}>Our Partners</h1>
        <div className={css.trusts_scroller} data-animated={motionEnabled}>
          <div className={css.trusts_scroller_inner}>
            {trustedByImages.map((c) => (
              <img
                key={c.id}
                src={c.src}
                alt={c.title}
                className={css.trusts_img}
              />
            ))}
            {trustedByImages.map((c) => (
              <img
                aria-hidden="true"
                key={`${c.id}-hidden`}
                src={c.src}
                alt={c.title}
                className={css.trusts_img}
              />
            ))}
          </div>
        </div>
      </section>
    </div>
  );
};

export default Trusts;
