import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faTag } from '@fortawesome/free-solid-svg-icons';
import css from './styles.module.css';

const links = [
  { name: 'Cloud Computing', href: '#cloud-computing' },
  { name: 'Artificial Intelligence', href: '#artificial-intelligence' },
  { name: 'Cybersecurity', href: '#cybersecurity' },
  { name: 'Data Analytics', href: '#data-analytics' },
  { name: 'DevOps', href: '#devops' },
  { name: 'Blockchain', href: '#blockchain' },
  { name: 'Internet of Things', href: '#internet-of-things' },
].map((l, id) => ({ ...l, id }));

const QuickLinksSidebar = () => (
  <div className={css.quick_links_sidebar}>
    <div className={css.search_widget}>
      <input type="text" placeholder="Search the blog..." />
      <FontAwesomeIcon icon={faSearch} />
    </div>
    <h4>Quick Links</h4>
    <ul className={css.quick_links_list}>
      {links.map((link) => (
        <li key={link.id}>
          <a href={link.href}>
            <FontAwesomeIcon icon={faTag} />
            {link.name}
          </a>
        </li>
      ))}
    </ul>
    <div className={css.popular_posts_widget}>
      <h4>Popular Posts</h4>
      <ul className={css.popular_posts_list}>
        <li>
          <a href="#popular-post-1">The Growth of Booking Application </a>
        </li>
        <li>
          <a href="#popular-post-2">Blockchain Beyond Bitcoin</a>
        </li>
        <li>
          <a href="#popular-post-3">Quantum Computing</a>
        </li>
      </ul>
    </div>
    <div className={css.newsletter_widget}>
      <h4>Subscribe to our Newsletter</h4>
      <form>
        <input type="email" placeholder="Your email address" />
        <button className={css.subscribe_text_xs} type="submit">Submit</button>
      </form>
    </div>
  </div>
);

export default QuickLinksSidebar;
