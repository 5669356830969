import GridPanel from '../../components/GridPanel';
import sites from '../../utils/sites';
import { useTheme } from '../../utils/themes';
import css from './style.module.css';

const SitesGrid = () => {
  const [theme] = useTheme();

  return (
    <section className={css.container} style={{ backgroundColor: theme.colorBG }}>
      <h1 className={css.section_heading} style={{ color: theme.colorFG }}>
        Feature Products
      </h1>
      <GridPanel maxColumns={4} minimumChildWidth={360}>
        {sites.map((site) => (
          <div key={site.title} className={css.card_wrap}>
            <section className={css.card} style={{ borderColor: theme.colorBGV }}>
              <header
                className={css.card_header}
                style={{ backgroundImage: `url(${site.homePoster})`, color: site.color }}
              >
                <h1
                  className="absolute left-6 bottom-0 m-0 font-semibold text-lg"
                  style={{ color: theme.colorFG }}
                >
                  {site.title}
                </h1>
              </header>
              <div className={css.card_body_wrap}>
                <p className={css.card_body} style={{ color: theme.colorFaint }}>
                  {site.summary2}
                </p>
                <footer className={css.footer}>
                  <a
                    className="more-btn"
                    href={site.homePage}
                    target="_blank"
                    rel="noreferrer"
                    style={{ color: theme.colorFaint, borderColor: theme.colorFaint }}
                  >
                    Visit Site
                  </a>
                </footer>
              </div>
            </section>
          </div>
        ))}
      </GridPanel>
    </section>
  );
};

export default SitesGrid;
