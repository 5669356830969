import { useCallback, useState } from 'react';
import css from './style.module.css';
import { postForm } from '../../api';
import TextBox, { matchesEmail } from '../../components/TextBox';
import { notification } from '../../utils';
import Header from '../Header';
import LoadingButton from '../../components/LoadingButton';
import { useTheme } from '../../utils/themes';
import Footer from '../Footer';
import banner from '../../assets/images/contact-banner.jpg';
import { Svg, paths } from '../../components/svg';

const Contact = () => {
  const [busy, setBusy] = useState(false);
  const [errors, setErrors] = useState({
    name: false,
    email: false,
    subject: false,
    content: false,
  });
  const [fields, setFields] = useState({
    name: '',
    email: '',
    subject: '',
    content: '',
  });
  const [theme] = useTheme();

  const handleTextChange = useCallback(({ target: { name, value } }) => {
    setFields((fields) => ({ ...fields, [name]: value }));
  }, []);

  const handleSubmit = useCallback((e) => {
    e.preventDefault();

    const errors = {
      name: !fields.name,
      email: !matchesEmail(fields.email),
      subject: !fields.subject,
      content: !fields.content,
    };

    if (errors.name || errors.email || errors.subject || errors.content) {
      setErrors(errors);
      return false;
    }

    setBusy(true);

    postForm('api/v1/messages', fields)
      .then(() => {
        setBusy(false);
        setFields({
          name: '',
          email: '',
          subject: '',
          content: '',
        });
        notification.showSuccess(
          'Your message was successfully sent! We appreciate your feedback.',
        );
      })
      .catch(() => {
        setBusy(false);
        notification.showError(
          'An error occurred while sending your message. Please try again.',
        );
      });
    return false;
  }, [fields]);

  return (
    <>
      <div className={css.top_panel} style={{ backgroundColor: theme.colorBG }}>
        <Header themeButton />
        <div className={css.form_row}>
          <section className={css.form_section}>
            <h1 className={css.form_heading}>Contact Us</h1>
            <p className={css.form_intro}>
              Have any question or concern about our products and how to leverage logaxp for
              you business? Send us a message in the form below
            </p>
            <form className={css.form} onSubmit={handleSubmit}>
              <TextBox
                type="text"
                name="name"
                id="name"
                label="Name"
                value={fields.name}
                error={errors.name ? 'Please enter your name!' : null}
                style={{ border: '1px solid #efefef' }}
                containerStyle={{ marginBottom: 0 }}
                onChange={handleTextChange}
                hideErrorOnNull
              />
              <TextBox
                type="email"
                name="email"
                id="email"
                label="Email"
                value={fields.email}
                error={errors.email ? 'Invalid Email Address!' : null}
                style={{ border: '1px solid #efefef' }}
                containerStyle={{ marginBottom: 0 }}
                onChange={handleTextChange}
                hideErrorOnNull
              />
              <TextBox
                type="text"
                name="subject"
                id="subject"
                label="Subject"
                value={fields.subject}
                error={errors.subject ? 'Please enter a subject!' : null}
                style={{ border: '1px solid #efefef' }}
                containerStyle={{ marginBottom: 0 }}
                onChange={handleTextChange}
                hideErrorOnNull
              />
              <div className={css.content_wrap}>
                <span className={css.label}>Message</span>
                <textarea
                  name="content"
                  className={css.content}
                  value={fields.content}
                  placeholder="Message"
                  onChange={handleTextChange}
                />
                {errors.content && (
                  <span className={`${css.label} ${css.error}`}>
                    Please enter message
                  </span>
                )}
              </div>
              <LoadingButton
                type="submit"
                label="Send Message"
                loading={busy}
                styles={{
                  fontSize: 14,
                  marginTop: 0,
                  width: 128,
                }}
              />
            </form>
          </section>
          <img src={banner} alt="contact" className={css.banner} />
        </div>
      </div>
      <div className={css.bg_white}>
        <div className={css.contacts}>
          <section className={css.contact}>
            <Svg
              path={paths.email}
              color="#011c39"
              width={32}
              height={32}
            />
            <h1 className={css.contact_heading}>Email</h1>
            <p className={css.contact_body}>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Suspendisse varius enim in ero.
            </p>
            <a
              href="mailto:enquiries@logaxp.com"
              className={css.contact_text_link}
              target="_blank"
              rel="noreferrer"
            >
              support@logaxp.com
            </a>
          </section>
          <section className={css.contact}>
            <Svg
              path={paths.chat}
              color="#011c39"
              width={32}
              height={32}
            />
            <h1 className={css.contact_heading}>Live Chat</h1>
            <p className={css.contact_body}>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Suspendisse varius enim in ero.
            </p>
            <span className={css.contact_text_link}>
              1105 Berry Street, Old Hickory, Tennessee 37138
            </span>
          </section>
          <section className={css.contact}>
            <Svg
              path={paths.phone}
              color="#011c39"
              width={32}
              height={32}
            />
            <h1 className={css.contact_heading}>Phone</h1>
            <p className={css.contact_body}>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Suspendisse varius enim in ero.
            </p>
            <a
              href="tel:+1 (615) 930-6090"
              className={css.contact_text_link}
              target="_blank"
              rel="noreferrer"
            >
              +1 (615) 930-6090
            </a>
          </section>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Contact;
