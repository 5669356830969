import React from 'react';
import css from './styles.module.css';
import docIcon3 from '../../../assets/images/blog-sample-poster.jpeg';
import docIcon4 from '../../../assets/images/Rectangle 3.jpg';
import docIcon5 from '../../../assets/images/logasys-screenshot.jpg';

const articles = [
  { title: 'The Future of AI', summary: 'Exploring the advancements in AI...', imgUrl: docIcon3 },
  { title: 'Blockchain Beyond Bitcoin', summary: 'Discovering applications of blockchain...', imgUrl: docIcon4 },
  { title: 'Quantum Computing', summary: 'Understanding the power of quantum computers...', imgUrl: docIcon5 },
].map((art, id) => ({ ...art, id }));

const RelatedArticles = () => (
  <div className={css.related_articles_container}>
    <h3>Related Reads</h3>
    <div className={css.related_articles_grid}>
      {articles.map((article) => (
        <article key={article.id} className={css.related_article_card}>
          <img src={article.imgUrl} alt={article.title} className={css.article_card_image} />
          <div className={css.article_card_content}>
            <h4 className={css.article_card_title}>{article.title}</h4>
            <p className={css.article_card_summary}>{article.summary}</p>
          </div>
        </article>
      ))}
    </div>
  </div>
);

export default RelatedArticles;
