import css from './styles.module.css';

const BlogHeader = () => (
  <header className={css.blog_header}>
    <div className={css.header_content}>
      <h1 className={css.header_title}>Welcome to the LogaXP Blog</h1>
      <p className={css.header_subtitle}>Dive into the latest insights and trends in technology</p>
    </div>
  </header>
);

export default BlogHeader;
