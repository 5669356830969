import { useMemo } from 'react';
import { motion } from 'framer-motion';
import { childrenProps } from '../utils/propTypes';
import { useWindowSize } from '../utils/hooks';

const styles = {
  container: {
    height: 'max-content',
    overflow: 'hidden',
  },
};

const smoothEffect = (property, hiddenValue, shownValue, duration) => ({
  show: { [property]: shownValue, transition: { duration: duration || 0.5 } },
  hide: { [property]: hiddenValue },
});

export const SlideFromLeft = ({ children }) => (
  <motion.div
    variants={smoothEffect('x', '-100%', 0)}
    initial="hide"
    whileInView="show"
  >
    {children}
  </motion.div>
);

SlideFromLeft.propTypes = {
  children: childrenProps,
};

SlideFromLeft.defaultProps = {
  children: null,
};

export const SlideFromRight = ({ children }) => (
  <motion.div
    variants={smoothEffect('x', '100%', 0)}
    initial="hide"
    whileInView="show"
  >
    {children}
  </motion.div>
);

SlideFromRight.propTypes = {
  children: childrenProps,
};

SlideFromRight.defaultProps = {
  children: null,
};

export const SlideFromBottom = ({ children }) => {
  const { width } = useWindowSize();
  const mini = useMemo(() => width < 640, [width]);

  return (
    <div style={styles.container}>
      {mini ? children : (
        <motion.div
          variants={smoothEffect('y', '100%', 0)}
          initial="hide"
          whileInView="show"
        >
          {children}
        </motion.div>
      )}
    </div>
  );
};

SlideFromBottom.propTypes = {
  children: childrenProps,
};

SlideFromBottom.defaultProps = {
  children: null,
};

export const SlideFromTop = ({ children }) => (
  <div style={styles.container}>
    <motion.div
      variants={smoothEffect('y', '-100%', 0)}
      initial="hide"
      whileInView="show"
    >
      {children}
    </motion.div>
  </div>
);

SlideFromTop.propTypes = {
  children: childrenProps,
};

SlideFromTop.defaultProps = {
  children: null,
};

export const FadeIn = ({ children }) => (
  <div style={styles.container}>
    <motion.div
      variants={smoothEffect('opacity', 0, 1, 1.5)}
      initial="hide"
      whileInView="show"
    >
      {children}
    </motion.div>
  </div>
);

FadeIn.propTypes = {
  children: childrenProps,
};

FadeIn.defaultProps = {
  children: null,
};

export const ScaleOut = ({ children }) => (
  <motion.div
    variants={smoothEffect('scale', 0, 1)}
    initial="hide"
    whileInView="show"
  >
    {children}
  </motion.div>
);

ScaleOut.propTypes = {
  children: childrenProps,
};

ScaleOut.defaultProps = {
  children: null,
};
