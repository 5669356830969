import { useCallback, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import css from './style.module.css';
import routes from '../../routing/routes';
import { Themes, useTheme } from '../../utils/themes';
import LogoLink from '../../components/LogoLink';
import { SvgButton, paths } from '../../components/svg';

const TOGGLE_MODE = 'toggle_mode';

const links = {
  Home: routes.home,
  About: routes.about,
  Contact: routes.contact,
};

const linkKeys = Object.keys(links);

const CLOSE = 'close';
const OPEN = 'open';

export const DrawerNavBar = ({
  theme,
  themeButton,
  isDarkMode,
  onToggleTheme,
}) => {
  const [open, setOpen] = useState(false);

  const handleClick = useCallback(({ target: { name } }) => {
    if (name === OPEN) {
      setOpen(true);
    } else if (name === CLOSE) {
      setOpen(false);
    }
  }, []);

  return (
    <section
      className="fixed top-0 left-0 w-screen h-screen pointer-events-none overflow-hidden z-[999999] block md:hidden"
    >
      <div className={css.drawer_nav_hamburger_nav}>
        <div className={css.main_nav_brand}>LogaXP</div>
        <div className="flex items-center gap-5 pointer-events-auto">
          {themeButton ? (
            <SvgButton
              type="button"
              name={TOGGLE_MODE}
              color={theme.colorAccent}
              path={isDarkMode ? paths.bulbOff : paths.bulbOn}
              onClick={onToggleTheme}
              title={isDarkMode ? 'Turn Off Dark Mode' : 'Turn On Dark Mode.'}
            />
          ) : null}
          <button
            type="button"
            aria-label={open ? 'Close Menu' : 'Open Menu'}
            name={open ? CLOSE : OPEN}
            color="#fff"
            title="Menu"
            className={`hamburger ${open ? 'open' : ''}`}
            onClick={handleClick}
            style={{ pointerEvents: 'all' }}
          >
            <span aria-hidden="true" />
          </button>
        </div>
      </div>
      <div className={`${css.drawer} ${open ? css.open : ''}`}>
        <nav className={css.drawer_body}>
          <ul className="plain-list">
            {linkKeys.map((key) => (
              <li key={key}>
                <Link to={links[key]} className={`${css.nav_link} ${css.drawer_link}`}>
                  {key}
                </Link>
              </li>
            ))}
            <li>
              <Link to="/" className={`${css.nav_link} ${css.drawer_link}`}>
                My Portal
              </Link>
            </li>
          </ul>
        </nav>
      </div>
    </section>
  );
};

DrawerNavBar.propTypes = {
  themeButton: PropTypes.bool,
  isDarkMode: PropTypes.bool,
  onToggleTheme: PropTypes.func.isRequired,
  theme: PropTypes.shape({
    colorBG: PropTypes.string,
    colorFG: PropTypes.string,
    colorAccent: PropTypes.string,
  }).isRequired,
};

DrawerNavBar.defaultProps = {
  themeButton: false,
  isDarkMode: false,
};

const MainNavbar = ({
  theme,
  pathname,
  isDarkMode,
  themeButton,
  onToggleTheme,
}) => (
  <header
    style={{ backgroundColor: isDarkMode ? theme.colorBG : 'transparent' }}
    className="relative w-full hidden md:block"
  >
    <nav className="mx-16 flex flex-row py-4">
      <div className={`${css.logo_menu_wrap} w-full flex flex-row`}>
        <LogoLink color={isDarkMode ? `${theme.colorFG}` : 'black'} className="w-1/12" />
        <ul className={`${css.menu_list} w-10/12 flex flex-row justify-center`}>
          {linkKeys.map((key) => (
            <li key={key}>
              <Link
                to={links[key]}
                style={{ color: pathname === links[key] ? theme.colorAccent : theme.colorFG }}
                className={css.menu_item}
              >
                {key}
              </Link>
            </li>
          ))}
        </ul>
        <div className="flex items-center gap-6">
          <Link to="/" className="accent-btn whitespace-nowrap">
            My Portal
          </Link>
          {themeButton ? (
            <SvgButton
              type="button"
              name={TOGGLE_MODE}
              color={isDarkMode ? theme.colorAccent : theme.colorFG}
              path={isDarkMode ? paths.bulbOff : paths.bulbOn}
              onClick={onToggleTheme}
              title={isDarkMode ? 'Turn Off Dark Mode' : 'Turn On Dark Mode.'}
            />
          ) : null}
        </div>
      </div>
    </nav>
  </header>
);

MainNavbar.propTypes = {
  themeButton: PropTypes.bool,
  isDarkMode: PropTypes.bool,
  pathname: PropTypes.string.isRequired,
  onToggleTheme: PropTypes.func.isRequired,
  theme: PropTypes.shape({
    colorBG: PropTypes.string,
    colorFG: PropTypes.string,
    colorAccent: PropTypes.string,
  }).isRequired,
};

MainNavbar.defaultProps = {
  themeButton: false,
  isDarkMode: false,
};

const Header = ({ themeButton }) => {
  const [theme, setTheme] = useTheme();
  const { pathname } = useParams();
  const isDarkMode = theme === Themes.DARK;

  const handleClick = useCallback(({ target: { name } }) => {
    if (name === TOGGLE_MODE) {
      setTheme(theme === Themes.DARK ? Themes.LIGHT : Themes.DARK);
    }
  }, [theme, setTheme]);

  return (
    <>
      <MainNavbar
        themeButton={themeButton}
        theme={theme}
        pathname={pathname}
        isDarkMode={isDarkMode}
        onToggleTheme={handleClick}
      />
      <DrawerNavBar
        themeButton={themeButton}
        theme={theme}
        isDarkMode={isDarkMode}
        onToggleTheme={handleClick}
      />
    </>
  );
};

Header.propTypes = {
  themeButton: PropTypes.bool,
};

Header.defaultProps = {
  themeButton: false,
};

export default Header;
