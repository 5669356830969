/* eslint-disable import/no-extraneous-dependencies */
import { toast } from 'react-toastify';

export const COLORS = {
  primary: '#0e2f5a',
  gray: '#67899c',
  red: '#9e0606',
  success: '#1e6d19',
};

export const imageColors = (() => {
  const canvas = document.createElement('canvas');
  canvas.style.width = '1000px';
  canvas.style.height = '1000px';
  const ctx = canvas.getContext('2d');
  const x = 10;
  const y = 10;

  const getColor = (src) => new Promise((resolve, reject) => {
    if (!ctx) {
      reject(new Error('function not available!'));
      return;
    }
    const img = new Image();
    img.crossOrigin = 'Anonymous';
    img.onload = () => {
      ctx.drawImage(img, 0, 0);
      const { data } = ctx.getImageData(x, y, 1, 1);
      resolve(data);
    };
    img.src = src;
  });

  return {
    getColor: (src) => new Promise((resolve, reject) => {
      getColor(src)
        .then(([r, g, b]) => resolve(`rgb(${r}, ${g}, ${b})`))
        .catch((err) => reject(err));
    }),
    getComplimentaryColors: (src, isBW) => new Promise((resolve, reject) => {
      getColor(src)
        .then(([r, g, b]) => {
          const bg = `rgb(${r}, ${g}, ${b})`;
          let fg;
          if (isBW) {
            fg = (r * 0.299 + g * 0.587 + b * 0.114) > 186
              ? '#000000'
              : '#FFFFFF';
          } else {
            fg = `rgb(${255 - r}, ${255 - g}, ${255 - b})`;
          }
          resolve([bg, fg]);
        })
        .catch((err) => reject(err));
    }),
  };
})();

const commonToastOptions = {
  autoClose: 6000,
  hideProgressBar: true,
  position: toast.POSITION.BOTTOM_RIGHT,
  pauseOnHover: true,
};

export const toastOptions = {
  DEFAULT: { ...commonToastOptions, type: toast.TYPE.DEFAULT },
  ERROR: { ...commonToastOptions, type: toast.TYPE.ERROR },
  INFO: { ...commonToastOptions, type: toast.TYPE.INFO },
  SUCCESS: { ...commonToastOptions, type: toast.TYPE.SUCCESS },
  WARNING: { ...commonToastOptions, type: toast.TYPE.WARNING },
};

export const notification = {
  showError: (msg) => toast(msg, toastOptions.ERROR),
  showInfo: (msg) => toast(msg, toastOptions.INFO),
  showSuccess: (msg) => toast(msg, toastOptions.SUCCESS),
  showWarning: (msg) => toast(msg, toastOptions.WARNING),
};
