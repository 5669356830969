import bookingSample from '../assets/images/booking-flex-sample.jpg';
import docSend from '../assets/images/docsend.jpeg';
import timeSync from '../assets/images/timesync.jpeg';
import bookMiz from '../assets/images/bookmiz.jpeg';
import logaSys from '../assets/images/logasys-screenshot.jpg';
import logaBeauty from '../assets/images/logabeauty.jpeg';
import taskBrick from '../assets/images/taskbrick.jpeg';
import taskBrickSample from '../assets/images/task-brick-dash.jpg';
import docsendSample from '../assets/images/docsend-dash.jpg';

const sites = [
  {
    homePoster: bookMiz,
    samplePoster: bookingSample,
    title: 'BookMiz',
    homePage: 'https://bookings-13l2.onrender.com',
    summary: 'Welcome to our convenient and user-friendly appointment booking web application! Join our growing community of service providers and users, where you can easily connect and find the services you need, right in your neighborhood. Discover, book, and experience the convenience of local services with us.',
    summary2: 'Join our community for easy appointment bookings. Discover and book local services hassle-free!',
    features: [
      'Grow your business and increase your sales',
      'Discover top service providers near you',
      'Streamline your appointments and clients management',
    ],
    color: '#011c39',
  },
  {
    homePoster: taskBrick,
    samplePoster: taskBrickSample,
    title: 'TaskBrick',
    homePage: 'https://tasksphere-k1ki.onrender.com',
    summary: 'Taskbrick is a comprehensive task management web application! Empower your company by streamlining project organization and team collaboration. Register your company, create employee profiles, and take control of your projects. Effortlessly create, assign, and track tasks, sprints, and projects, ensuring seamless progress tracking and efficient teamwork.',
    summary2: 'Simplify task management! Register, create profiles, assign tasks, and track progress effortlessly',
    features: [
      'Grow your business and increase your sales',
      'Discover top service providers near you',
      'Streamline your appointments and clients management',
    ],
    color: '#011c39',
  },
  {
    homePoster: docSend,
    samplePoster: docsendSample,
    title: 'DocSend',
    homePage: 'https://teal-loga.netlify.app',
    summary: 'DocSend is a versatile web application designed to streamline document sharing and signing processes. With DocSend, users can securely send documents to others for various purposes, including signing agreements, reviewing proposals, and collaborating on projects. The platform offers a range of features to simplify document management and enhance productivity.',
    summary2: 'DocSend streamlines document sharing and signing. Send securely for agreements, reviews, and collaboration.',
    features: [
      'Document Upload and Management',
      'Secure Sharing and Access Control',
      'Electronic Signatures',
    ],
    color: '#011c39',
  },
  {
    homePoster: logaBeauty,
    samplePoster: bookingSample,
    title: 'Loga Beauty Hub',
    homePage: 'https://beauty-hub.onrender.com',
    summary: 'Loga Beauty Hub connects users with nearby salons and barber shops, offering easy appointment booking and online product purchases. Find the perfect haircut or beauty products, all from your mobile device. Elevate your beauty routine with Loga Beauty Hub!',
    summary2: 'Find nearby salons, book appointments, buy products. Elevate your beauty routine today!',
    features: [
      'Easily discover nearby salons and barber shops based on location',
      'Seamlessly book appointments with your favorite service providers.',
      'Expose your business to a larger audience.',
    ],
    color: '#011c39',
  },
  {
    homePoster: logaSys,
    samplePoster: bookingSample,
    title: 'Loga Systems',
    homePage: 'https://logasys.netlify.app',
    summary: "Welcome to LogaSys, your gateway to top-tier IT expertise. At LogaXP, we pride ourselves on our team of skilled IT engineers, software developers, and DevOps professionals who are ready to propel your business to the next level. Explore our website to discover the depth of our technical prowess and how we can tailor our services to meet your unique needs. Let's drive your IT solutions forward, together.",
    summary2: "Welcome to LogaSys! We're your IT experts. Explore our site to see how we can boost your business!",
    features: [
      'Innovative Solutions',
      'Technical Prowess',
      'Responsive Support',
    ],
    color: '#011c39',
  },
  {
    poster: timeSync,
    homePoster: timeSync,
    samplePoster: bookingSample,
    title: 'Time Sync',
    homePage: 'https://logasys.netlify.app',
    summary: 'TimeSync is your ultimate solution for efficient employee time tracking and management. With TimeSync, businesses can streamline their workforce management processes, ensuring accurate and reliable tracking of employee attendance and work hours. Say goodbye to manual timekeeping methods and embrace the simplicity and convenience of TimeSync.',
    summary2: 'TimeSync revolutionizes time tracking, streamlining processes for accuracy.',
    features: [
      'Geolocation Tracking',
      'Attendance Reporting',
      'Overtime Management',
    ],
    color: '#011c39',
  },
];

export const featuredSites = sites.filter((s, idx) => idx < 3);

export default sites;
