/* eslint-disable-next-line */
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import routes from './routes';
import Home from '../containers/Home';
import Contact from '../containers/Contact';
import AboutUs from '../containers/AboutUs';
import { PrivacyPolicy, TermsOfUse } from '../containers/PrivacyAndTerms';
import Blog from '../containers/Blog';
import Products from '../containers/Products';
import Error404 from '../containers/Error404';

const router = createBrowserRouter([
  {
    path: routes.home,
    element: <Home />,
  },
  {
    path: routes.contact,
    element: <Contact />,
  },
  {
    path: routes.about,
    element: <AboutUs />,
  },
  {
    path: routes.blogs(':id'),
    element: <Blog />,
  },
  {
    path: routes.blogsList,
    element: <Blog />,
  },
  {
    path: routes.privacyPolicy,
    element: <PrivacyPolicy />,
  },
  {
    path: routes.termsAndConditions,
    element: <TermsOfUse />,
  },
  {
    path: routes.allProducts,
    element: <Products />,
  },
  {
    path: '*',
    element: <Error404 />,
  },
]);

const Router = () => <RouterProvider router={router} />;

export default Router;
