import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowRight,
  faClock,
  faComment,
  faShareAlt,
  faThumbsUp,
  faStar,
} from '@fortawesome/free-solid-svg-icons';
import {
  faFacebookF,
  faTwitter,
  faLinkedinIn,
} from '@fortawesome/free-brands-svg-icons';
import css from './styles.module.css';
import docIcon3 from '../../../assets/images/tasks-mgt-screenshot.jpg';
import docIcon4 from '../../../assets/images/blog-sample-poster.jpeg';
import Chris from '../../../assets/images/testimonial-2.jpg';

const articlesByAuthor = [
  {
    title: 'Decoding Cybersecurity',
    description: 'An in-depth look at cybersecurity...',
    id: 1,
  },
  {
    title: 'Cloud Computing',
    description: 'Understanding the basics of ...',
    id: 2,
  },
  {
    title: 'Future of AI',
    description: 'Exploring the advancements in AI...',
    id: 3,
  },
];

const BlogSideBar = () => (
  <>
    <aside className={css.blog_sidebar}>
      <section className={`${css.blog_sidebar_item} ${css.about_author}`}>
        <div className={css.author_info}>
          <img src={Chris} alt="Author" className={css.sidebar_author_image} />
          <div className={css.author_details}>
            <h4>Chris Bajo</h4>
            <p>Seasoned IT Expert</p>
            <p>
              Chris Bajo is a seasoned IT expert with over a decade of
              experience...
            </p>
            <button type="button" className={`btn ${css.read_more}`}>Read Full Bio</button>
          </div>
        </div>
      </section>
      <section className={`${css.blog_sidebar_item} ${css.blog_info}`}>
        <div className={css.blog_info_content}>
          <time dateTime="2023-02-10" className={css.blog_date}>
            Feb 10, 2023
          </time>
          <div className={css.blog_stats}>
            <span className={css.stats_item}>
              <FontAwesomeIcon icon={faClock} />
              7 min read
            </span>
            <span className={css.stats_item}>
              <FontAwesomeIcon icon={faComment} className="text-green-500" />
              &nbsp;5 Comments
            </span>
          </div>
          <div className={css.blog_categories}>
            <span className={css.category}>Wellness</span>
            <span className={css.category}>Massage</span>
            <span className={css.category}>Relaxation</span>
          </div>
          <div className={css.blog_reactions}>
            <button type="button" className={`${css.reaction_btn} ${css.like_btn}`}>
              <FontAwesomeIcon icon={faThumbsUp} className="text-blue-500" />
              &nbsp;Like
            </button>
            <button type="button" className={`${css.reaction_btn} ${css.favorite_btn}`}>
              <FontAwesomeIcon icon={faStar} className="text-yellow-500" />
              &nbsp;Favorite
            </button>
          </div>
        </div>
      </section>

      <section className={`${css.blog_sidebar_item} ${css.blog_cover}`}>
        <img src={docIcon4} alt="Blog Cover" className={css.cover_image} />
      </section>

      <section className={`${css.blog_sidebar_item} ${css.social_share}`}>
        <button type="button" className={css.share_btn} aria-label="Share this article">
          <FontAwesomeIcon icon={faShareAlt} className={css.share_icon} />
        </button>
        <button
          type="button"
          className={`${css.social_btn} ${css.facebook}`}
          aria-label="Share on Facebook"
        >
          <FontAwesomeIcon icon={faFacebookF} />
        </button>
        <button type="button" className={`${css.social_btn} ${css.twitter}`} aria-label="Share on Twitter">
          <FontAwesomeIcon icon={faTwitter} />
        </button>
        <button
          type="button"
          className={`${css.social_btn} ${css.linkedin}`}
          aria-label="Share on LinkedIn"
        >
          <FontAwesomeIcon icon={faLinkedinIn} />
        </button>
      </section>
    </aside>

    <div className={css.related_articles}>
      <h3 className={css.header_authur}>Reads by Authur</h3>
      {articlesByAuthor.map((article) => (
        <div key={article.id} className={css.related_articles}>
          <img
            src={docIcon3}
            alt={article.title}
            className={css.related_article_images}
          />
          <h4>{article.title}</h4>
          <p>{article.description}</p>
          <button aria-label="right" type="button" className={css.read_more}>
            <FontAwesomeIcon icon={faArrowRight} />
          </button>
        </div>
      ))}
    </div>
  </>
);

export default BlogSideBar;
