import { Link } from 'react-router-dom';
import lx from '../assets/images/logaxp.png';

const styles = {
  mainNaBrand: {
    display: 'flex',
    gap: 4,
    alignItems: 'center',
    width: 'max-content',
  },
  logo: {
    width: 24,
  },
  navBrandWrap: {
    fontSize: 18,
    fontWeight: 'bold',
    color: '#00416a',
    textAlign: 'center',
  },
  loga: {
    color: '#00416a',
  },
  xp: {
    color: '#89e101',
  },
};

// eslint-disable-next-line react/prop-types
const LogoLink = ({ color }) => (
  <Link to="/" style={styles.mainNaBrand}>
    <img src={lx} alt="logo" style={styles.logo} />
    <div style={{ color }} className="font-display text-4xl font-semibold leading-10 tracking-normal text-left">
      Loga
      <span className="text-[#89e101]">XP</span>
    </div>
    {/* <div style={styles.navBrandWrap}>
      <span style={logaStyle}>Loga</span>
      <span style={styles.xp}>XP</span>
    </div> */}
  </Link>
);

export default LogoLink;
