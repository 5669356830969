import { useEffect, useRef, useState } from 'react';

export const useWindowWidth = () => {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [width]);

  return width;
};

export const useWindowSize = () => {
  const [size, setSize] = useState({ width: window.innerWidth, height: window.innerHeight });

  useEffect(() => {
    const handleResize = () => setSize({ width: window.innerWidth, height: window.innerHeight });

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [size]);

  return size;
};

export const useHover = (elementRef) => {
  const [isHovered, setHovered] = useState(false);

  useEffect(() => {
    const element = elementRef.current;
    if (!element) return;
    element.addEventListener('mouseenter', () => setHovered(true));
    element.addEventListener('mouseleave', () => setHovered(false));
  });

  return isHovered;
};

export const useFocus = (elementRef) => {
  const [isFocused, setFocused] = useState(false);

  useEffect(() => {
    const element = elementRef.current;
    if (!element) return;
    element.addEventListener('focus', () => setFocused(true));
    element.addEventListener('blur', () => setFocused(false));
  });

  return isFocused;
};

export const useHeaderClosed = () => {
  const [isHeaderClosed, setHeaderClosed] = useState(false);
  const scrollTop = useRef(0);

  useEffect(() => {
    const handleScrolling = () => {
      const y = window.scrollY;
      if (y < 300) {
        setHeaderClosed(false);
      } else if (y > scrollTop.current) {
        setHeaderClosed(true);
      } else {
        setHeaderClosed(false);
      }
      scrollTop.current = y;
    };

    handleScrolling();

    document.addEventListener('scroll', handleScrolling);
    return () => document.removeEventListener('scroll', handleScrolling);
  }, []);

  return isHeaderClosed;
};
